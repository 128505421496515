/********** Global Css ***************/

* {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', Helvetica;
}

a {
    display: inline-block;
}

a:focus,
.btn:focus {
    outline: 0px;
}

ol,
ul {
    list-style: none;
}

.wrapper {
    /* overflow-x: hidden; */
    min-height: calc(100% - 36px);
    width: 100%;
    min-width: 1000px;
}


html,
body,
.mainConent,
.main,
.content,
.tabContent {
    /*min-height: 100%;*/
    width: 100%;
    overflow: hidden;
}

body {
    overflow-x: hidden;
    background: #F8F8F8;
}
.text-center{
    text-align: center;
}


::-webkit-scrollbar {
    width: 5px;
    left: 0;           
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.header{
    padding: 20px 30px;
    text-align: right;
    margin: 0px 0px 50px;
}
.search_sec {
    max-width: 682px;
    margin: 0px auto 40px;
    text-align: center;
}
.clock-widget{
    font-size: 20px;
    color: #707070;
}
.logoTxt{
    margin-bottom: 1.2rem;
}
.logoTxt img{
    width:300px;
}
.plus{
    margin-bottom: 8px;
}
.colon{}
.cancel{
    color: #707070;
    font-size: 14px;
    margin: 10px;
    cursor: pointer;
}
.searchForm{
    width: 682px;
    height: 60px;
    background: #fff;
    box-shadow: 0px 10px 40px 0px #5572c657;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #556080;
}
.searchForm .search{
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 20px;
    padding: 0 146px 0 50px;
    outline: 0;
    background: #ffffff;
}
.searchForm .search-btn{
    width: 130px;
    height: 52px;
    background: #009500;
    border: none;
    border-radius: 6px;
    position: absolute;
    right: 4px;
    top: 3px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
} 

.searchForm .search-icon{
    position: absolute;
    top: 20px;
    left: 18px;
}

.convert-btn{
    width: 250px;
    height: 56px;
    background: #032588;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    margin: auto;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.content{}
.file-icon{
    vertical-align: sub;
}
.content .blockSec{
    max-width: 450px;
    margin: auto;
    text-align: center;
}
.content .blockSec h3{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 320px;
    height: 56px;
    background: #032588;
    margin: auto;
    line-height: 56px;
    z-index: 1;
    position: relative;
    border-radius: 10px;
}
.content .blockSec p{
    font-size: 14px;
}
.file-select{
    position:relative;
    width: 250px;
    margin: auto;
    padding-top: 1px;
}
.file-select-name{
    width: 248px;
    background: #fff;
    font-size: 16px;
    color: #000;
    border: 1px solid #707070;
    padding: 24px 0;
    margin: auto;
    margin-top: -13px;
}
.file-upload .file-select input[type=file] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.fileUpload_sec{
    display: none;
}
.convert-btn.open + .fileUpload_sec{
    display: block;
}
.file-selected{
    position: relative;
}
.convertPdf{
}
.caret{
    margin-left:20px;
    transition: .4s all ease;
}
.convert-btn.open .caret{
    transform: rotate(180deg);
}

.convertProcess{
    position:relative;
    text-align: left;
    background: #fff;
    padding: 4px 4px 4px 40px;
    color: #032588;
    max-width: 250px;
    margin: auto;
}
.convertProcess:before {
    content: '';
    background: url(../images/loader.png) center center no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 14px;
    background-size: contain;
    top: 50%;
    margin-top: -10px;
    animation: rotating 2s linear infinite;
}

.defaultBtn{
    background: #1854bc;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    width: 110px;
    margin: auto;
    cursor: pointer;
}

.defaultBtn img{
    height: 18px;
    vertical-align: sub;
}

.success_Sec .file-select-name{
    margin-top: 0;
    padding: 10px 0;
}
.content .blockSec .success_Sec h3{
    line-height: 20px;
    padding: 20px 20px 6px;
}
.success_Sec .centerText{
    margin: 16px auto;
}

.terms_policy_sec{
    font-size: 14px;
    color: #9A9A9A;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
}

.terms_policy_sec ul{
    display: flex;
}
.terms_policy_sec a{margin: 0 6px;text-decoration: none;color: #9A9A9A;}

@keyframes rotating{
    0%{
      transform:rotate(0deg)
    }
    100%{
        transform:rotate(359deg)
      }
}
       

/************* Media Query *****************/
@media (max-width:1200px){
    html, body, .mainConent, .main, .content, .tabContent,.wrapper  {
     overflow-x:auto;
 }
}

@media (max-height:720px){
   .header {
         margin: 0px 0px 50px;
         }
         .search_sec {
         margin: 0px auto 35px;

     }
}

#pdf-step1{
    display: block;
}

#pdf-step2, #pdf-step3, #pdf-step4{
    display: none;
}